import { Link } from "react-router-dom";
import Mailto from "./Mailto";
// import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="connect" className="footer relative z-20 bg-brand-black">
      <div className="footer-links container mx-auto flex h-48 w-full flex-col items-center justify-center gap-2 px-8 text-white">
        <Mailto
          label="contact@cloudmind.me"
          mailto="mailto:contact@cloudmind.me?Subject=Cloudmind.me Website Contact"
        />
        <p className="w-full text-center font-sans text-sm lg:text-base">
          Copyright © {currentYear} CloudMind. All Rights Reserved.{" "}
        </p>
        <div className="text-sm">
          <Link
            className="transition-colors duration-300 hover:text-brand-alt"
            to="/terms"
          >
            Terms
          </Link>{" "}
          |{" "}
          <Link
            className="transition-colors duration-300 hover:text-brand-alt"
            to="/privacy"
          >
            Privacy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
