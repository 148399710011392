import React from "react";
import { useInView } from "react-intersection-observer";
import infoImage from "./../info-image.webp";

const InformationSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-200px 0px",
  });

  return (
    <div className="relative mt-8 flex w-full flex-col items-center overflow-hidden pb-16 lg:mt-16 lg:min-h-0 lg:flex-row lg:justify-center 2xl:mt-[15vh] 2xl:min-h-[100vh] 2xl:pb-80">
      <div className="static right-0 flex w-full items-center justify-center px-4 py-16 lg:absolute lg:w-1/2 lg:px-0">
        <img
          ref={scrollRef}
          data-inview={scrollInView}
          className={`w-full rounded-2xl object-contain shadow-2xl md:w-1/2 lg:w-full lg:rounded-bl-[48px] lg:rounded-br-none lg:rounded-tl-[48px] lg:rounded-tr-none ${
            scrollInView ? "fade-in-left" : "opacity-0"
          }`}
          src={infoImage}
          alt="Cloudmind.me - Ai helper"
        />
      </div>
      <div className="container relative mx-auto py-4 lg:py-32">
        <div
          ref={scrollRef}
          data-inview={scrollInView}
          className={`relative mx-0 flex w-full items-center justify-start lg:mx-auto lg:w-2/3 ${
            scrollInView ? "fade-in-up" : "opacity-0"
          }`}
        >
          <div className="w-full px-4 text-base lg:w-2/5 lg:px-0 lg:text-lg xl:text-xl 2xl:w-1/3">
            <div className="flex flex-col gap-4">
              <h2 className="font-title text-2xl text-brand-main lg:text-3xl">
                Our Approach
              </h2>
              <p className="font-sans font-normal">
                At CloudMind, we aim to tackle the prevalent and often
                disregarded issue of loneliness.
              </p>
              <p className="font-sans font-normal">
                Our unwavering commitment lies in developing innovative
                solutions, with our initial focus on eldercare.
              </p>
              <p className="font-sans font-normal">
                By harnessing technology, we aim to alleviate loneliness and
                enhance the well-being of individuals, recognizing the diverse
                range of applications our solution can offer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
