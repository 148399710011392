import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import PrevTerms from "./pages/PrevTerms";
import PrevPrivacy from "./pages/PrevPrivacy";
import NotFound from "./pages/NotFound";
import bgGrid from "./bg-grid.webp";

function App() {
  return (
    <div id="top" className="App">
      <header>
        <NavBar />
      </header>
      <div
        className="content bg-auto bg-top-4 bg-repeat-y"
        style={{ backgroundImage: `url(${bgGrid})` }}
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/previous-terms" element={<PrevTerms />} />
          <Route path="/previous-privacy" element={<PrevPrivacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
