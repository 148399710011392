import React from "react";
import { useInView } from "react-intersection-observer";
// import heroImage from "./../hero-image.webp";

const HeroSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <div className="min-h-auto relative flex w-full items-center overflow-hidden lg:min-h-[90vh] lg:justify-center">
      <div className="container relative mx-auto px-4 pb-24 pt-32 lg:px-0 lg:py-32">
        <div
          ref={scrollRef}
          data-inview={scrollInView}
          className={`relative mx-0 flex w-full flex-col items-center justify-center lg:mx-auto lg:w-2/3 ${
            scrollInView ? "fade-in-up" : "opacity-0"
          }`}
        >
          <div className="relative h-0 w-full pb-[56.25%]">
            <iframe
              className="absolute left-0 top-0 h-full w-full"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/jCYwdbju0Q8?si=d36PnIvkDWmYn76m&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          {/* <img
            className="w-full rounded-2xl object-contain shadow-2xl lg:rounded-[48px]"
            src={heroImage}
            alt="Cloudmind.me - Ai helper"
          /> */}
          <div className="flex flex-col items-center rounded-[16px] p-4 lg:bg-transparent xl:p-8">
            <h2 className="font-title text-lg uppercase text-brand-alt lg:text-2xl xl:text-3xl 2xl:text-4xl">
              Making connections
            </h2>
            <h1 className="font-sans text-2xl uppercase text-brand-dark lg:text-3xl xl:text-4xl 2xl:text-5xl">
              One companion at a time
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
