import React from "react";
import { useInView } from "react-intersection-observer";
import iconOne from "./../icon-1.png";
import iconTwo from "./../icon-2.png";
import iconThree from "./../icon-3.png";

const PointSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  const { ref: scrollRefLate, inView: scrollInViewLate } = useInView({
    triggerOnce: true,
    rootMargin: "-300px 0px",
  });

  return (
    <div className="relative flex w-full items-center overflow-hidden lg:justify-center">
      <div className="container relative mx-auto lg:py-16">
        <div
          ref={scrollRef}
          data-inview={scrollInView}
          className={`flex w-full flex-col items-center justify-center px-4 pb-16 text-base lg:px-0 lg:pb-32 lg:text-lg xl:text-xl ${
            scrollInView ? "fade-in-up" : "opacity-0"
          }`}
        >
          <h2 className="font-title text-2xl text-brand-main lg:text-3xl">
            CloudMind's Mission
          </h2>
          <p className="mt-4 text-center font-sans">
            To help reduce loneliness by providing a Constant Companion for
            everyone.
          </p>
        </div>
        <div className="relative mx-0 flex w-full items-center justify-center lg:mx-auto lg:w-2/3">
          <div className="grid w-full grid-cols-1 gap-y-12 sm:grid-cols-3 lg:gap-y-0">
            <div
              ref={scrollRefLate}
              data-inview={scrollInViewLate}
              className={`relative flex w-auto flex-col items-center gap-y-4 text-center text-xl lg:text-2xl  ${
                scrollInViewLate ? "fade-in-up" : "opacity-0"
              }`}
            >
              <span className="flex h-16 w-16 rounded-full bg-brand-dark p-2">
                <img
                  className="h-full w-full"
                  src={iconOne}
                  alt="Cloudmind.me - Ai helper"
                />
              </span>
              <h3>
                Reduce <br />
                Loneliness
              </h3>
            </div>
            <div
              ref={scrollRefLate}
              data-inview={scrollInViewLate}
              className={`relative flex w-auto flex-col items-center gap-y-4 text-center text-xl lg:text-2xl  ${
                scrollInViewLate ? "fade-in-down" : "opacity-0"
              }`}
            >
              <span className="flex h-16 w-16 rounded-full bg-brand-dark p-2">
                <img
                  className="h-full w-full"
                  src={iconTwo}
                  alt="Cloudmind.me - Ai helper"
                />
              </span>
              <h3>
                Provide <br />
                Support
              </h3>
            </div>
            <div
              ref={scrollRefLate}
              data-inview={scrollInViewLate}
              className={`relative flex w-auto flex-col items-center gap-y-4 text-center text-xl lg:text-2xl  ${
                scrollInViewLate ? "fade-in-up" : "opacity-0"
              }`}
            >
              <span className="flex h-16 w-16 rounded-full bg-brand-dark p-2">
                <img
                  className="h-full w-full"
                  src={iconThree}
                  alt="Cloudmind.me - Ai helper"
                />
              </span>
              <h3>
                Creates <br />
                Connection
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointSection;
